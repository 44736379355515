<template>
  <div>
    <div class="flex flex-row py-2">
      <div ref="tabContainer" class="container flex flex-row gap-2 md:overflow-x-hidden overflow-x-auto">
        <slot name="tabs" />
      </div>
      <div class="flex-grow" />
      <div class="flex flex-row gap-2 align-middle">
        <slot name="links" />
      </div>
    </div>
    <div>
      <slot name="panes" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { type PropType, provide, ref } from 'vue';

const selectedTab = ref(0);

defineProps({
  type: { type: String as PropType<'pill'>, required: true },
});

provide('selectedTab', selectedTab);
</script>
<style scoped lang="scss">
/* To hide Scrollbar for small screens */
.container {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.container::-webkit-scrollbar {
  width: 0;
  height: 0;
  display: none;
}
</style>
