<template>
  <div class="px-4 py-3 rounded-lg cursor-pointer select-none pill" :class="[{ active }]" @click="selectedTab = index">
    <VIcon v-if="icon" :name="icon" class="mr-1" />
    {{ title }}
  </div>
</template>

<script setup lang="ts">
import { computed, inject, type Ref } from 'vue';
import { VIcon } from '@vonage/vivid-vue';

const selectedTab = inject<Ref<number>>('selectedTab');

const props = defineProps({
  index: { type: Number, required: true },
  title: { type: String, required: true },
  icon: { type: String },
});

const active = computed(() => props.index === selectedTab?.value);
</script>

<style scoped lang="scss">
.pill {
  background-color: var(--pill-tab-bg-color, transparent);
  color: var(--pill-tab-fg-color, var(--vvd-color-neutral-900));
  font: var(--pill-tab-font, var(--vvd-typography-base-bold));

  &:hover {
    background-color: var(--pill-tab-bg-color-hover, #f2f2f2);
  }

  &.active {
    background-color: var(--pill-tab-active-bg-color, var(--vvd-color-neutral-900));
    color: var(--pill-tab-active-fg-color, var(--vvd-color-canvas));

    &:hover {
      background-color: var(--pill-tab-active-bg-color-hover, #252525);
    }
  }
}
</style>
