<template>
  <div :class="{ hidden: !active }"><slot /></div>
</template>

<script setup lang="ts">
import { computed, inject, type Ref } from 'vue';

const selectedTab = inject<Ref<number>>('selectedTab');

const props = defineProps({
  index: { type: Number, required: true },
});

const active = computed(() => props.index === selectedTab?.value);
</script>
